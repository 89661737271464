import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';

const StyledTooltip = styled.div`
  a {
    color: #26CCE2;
    font-weight: 800;
  }
`;

export default function InfoTooltip({ content }) {
  return (
    <div className="pr-2">
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            <StyledTooltip dangerouslySetInnerHTML={{ __html: content }} />
          </Tooltip>
        }
      >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
          >
            <circle
              cx="8"
              cy="8"
              r="7.15"
              stroke="#D8DAE6"
              strokeWidth="0.7"
            ></circle>
            <path
              fill="#D8DAE6"
              d="M8.436 9.649c-.02-.1-.02-.2-.02-.27 0-.36.13-.74.48-.99l.43-.31c.59-.41 1.01-.96 1.01-1.73 0-1-.77-1.96-2.12-1.96-1.5 0-2.16 1.09-2.16 2.01 0 .18.02.34.06.5l.83.1c-.05-.13-.08-.33-.08-.5 0-.68.42-1.36 1.35-1.36.85 0 1.28.58 1.28 1.22 0 .44-.23.82-.6 1.09l-.44.31c-.56.4-.81.88-.81 1.53 0 .13.01.25.02.36h.77zm-.97 1.42a.595.595 0 101.19.01.595.595 0 00-1.19-.01z"
            ></path>
          </svg>
      </OverlayTrigger>
    </div>
  );
}
