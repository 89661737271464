import styled from 'styled-components';
import { font } from '../../atoms/typography';

export const PricingBlockContainerStyles = styled.section`
  margin-bottom: 130px;
  margin-top: 64px;

  @media (max-width: 990px) {
    display: none;
  }

  .block-section {
    margin-bottom: 32px;

    .section-margin {
      margin-bottom: 16px;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
    }

    .block-row {
      align-items: stretch;
    }

    &.block-menu {
      position: sticky;
      top: 165px;
      background: #fff;
      border-radius: 16px;
      width: 100%;
      padding: 13.5px 0;
      z-index: 1;
    }

    &.sticky-block-menu {
      box-shadow: 0px 12px 16px -4px rgba(19, 48, 205, 0.1),
        0px 4px 6px -2px rgba(19, 48, 205, 0.05);
      z-index: 100;
      margin-left: -30px !important;
      margin-right: -30px;

      @media (max-width: 1199.98px) {
        top: 140px;
      }

      @media (min-width: 1298px) {
        padding: 13.5px 50px !important;
        width: 1298px !important;
        margin-left: -64px !important;
        margin-right: -15px;
      }

      .description {
        @media (min-width: 1298px) {
          padding-left: 14px;
        }
      }

      .row {
        padding-left: 15px;
        padding-right: 15px;
        margin-left: 0;
        margin-right: 0;

        @media (min-width: 1298px) {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .perk-block-content {
        padding: 8px 0;

        .billing {
          display: none;
        }

        .price {
          ${font('text', 'sm', '400')}
          color: #6F7283;
          margin: 0;

          h4,
          h5,
          .number {
            ${font('text', 'sm', '400')}
            color: #6F7283;
            margin: 0 !important;
          }

          &.call-price {
            p {
              line-height: 1.35;
            }
          }
        }
      }

      button {
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        color: #1c3eff;
        padding: 0;
        border: none;
        background: transparent !important;
      }
    }
  }

  && .pricing-btn {
    width: 100%;

    &:hover {
      transform: scale(1.05);
    }
  }

  .perk-title {
    font-size: 14.4px;
    color: var(--text-color);
  }

  h5 {
    font-weight: 700;
    color: var(--dark-color);
    margin-bottom: 4px;
  }

  .heading-4 {
    font-size: 28.13px;
    line-height: 1.1em;
    font-weight: 700;
    margin-bottom: 4px;
  }

  .subhead {
    max-width: 149px;
    p {
      font-size: 14.4px;
      margin-bottom: 0;
    }
  }

  .perk-row {
    align-items: center;
  }

  .perk-col {
    margin-right: 8px;
    margin-bottom: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .perk-block {
      background-color: #f9fbfe;
      width: 100%;
      flex: 1 0 auto;

      &.preferred {
        background-color: #edf3fd;
      }

      &-content {
        padding: 24px 21px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          ${font('display', 'xs', '700')}
          margin-bottom: 8px;
          text-transform: none;
        }

        .price {
          font-weight: 700;
          color: #000;
          display: flex;
          align-items: center;
          margin-bottom: 8px;

          &.call-price {
            p {
              font-size: 18px;
              margin: 0;
              line-height: 1.55;
            }
          }

          .number {
            ${font('display', 'md', '700')}
            margin: 0 4px;
            line-height: 1;
          }
        }

        .billing {
          color: var(--subtle-text-color);
          font-size: 14.4px;
          margin-bottom: 0;
        }

        .enterprise-icon {
          margin-bottom: 0;

          img {
            height: 36px;
            width: 36px;
          }
        }
      }
    }

    .btn-outline-primary {
      border-width: 1px;
      padding-top: 12px;
      padding-bottom: 12px;
      letter-spacing: 1.4px;
    }

    .btn-primary {
      letter-spacing: 1.4px;
      padding-top: 12px;
      padding-bottom: 12px;
    }

    .small-block {
      background-color: #f9fbfe;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;

      &.preferred {
        background-color: #edf3fd;
      }

      figure {
        margin-bottom: 0;

        &.gray-img {
          img {
            filter: grayscale(1) opacity(0.5);
          }
        }
      }

      .subhead {
        p {
          font-size: 11.52px;
          margin-bottom: 0;
          color: var(--text-color);

          strong {
            font-weight: 700;
          }
        }
      }
    }

    a {
      button {
        font-size: 18px;

        @media (max-width: 1200px) {
          font-size: 16px;
        }
      }
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`;
