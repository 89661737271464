import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { PricingToggleContext } from '../../contexts/pricingToggleContext';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import { PricingBlockContainerStyles } from '../../styles/Components/PricingBlockContainer/PricingBlockStyles';
import Img from '../../utils/OptimizedImage';
import ToolTip from '../ToolTip/InfoTooltip';

const PricingBlockContainer = ({ component }) => {
  const { blockSection } = component;
  const { isAnnualPlan } = useContext(PricingToggleContext);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const blockMenu = document.querySelector('.block-menu');
    const blockMenuTop =
      window.scrollY + blockMenu.getBoundingClientRect().top - 165;
    const priceToggle = document.getElementById('price-toggle');
    const priceToggleTop =
      window.scrollY + priceToggle?.getBoundingClientRect().top - 165;

    if (window.scrollY >= blockMenuTop) {
      blockMenu.classList.add('sticky-block-menu');
      priceToggle?.classList.add('sticky-block-menu');
      setIsScrolled(true);
    } else {
      blockMenu.classList.remove('sticky-block-menu');
      priceToggle?.classList.remove('sticky-block-menu');
      setIsScrolled(false);
    }

    if (priceToggleTop) {
      if (priceToggleTop >= blockMenuTop) {
        priceToggle?.classList.add('hidden');
      } else {
        priceToggle?.classList.remove('hidden');
      }
    }

    handleResize();
  };

  const handleResize = () => {
    const stickyEl = document.querySelector('.sticky-block-menu');
    const blockEl = document.querySelector('.block-menu');
    const applyStyleOverrides =
      window.innerWidth < 1298 && window.innerWidth > 990;

    if (blockEl) {
      blockEl.style.width = applyStyleOverrides ? 'auto' : '';
      blockEl.style.marginLeft = applyStyleOverrides ? 0 : '';
      blockEl.style.paddingLeft = applyStyleOverrides ? 0 : '';
      blockEl.style.paddingRight = applyStyleOverrides ? 0 : '';
    }

    if (stickyEl) {
      const padding = Math.max(
        0,
        (window.innerWidth -
          document.querySelector('.block-container').offsetWidth) /
          2 -
          15
      );
      stickyEl.style.width = applyStyleOverrides
        ? window.innerWidth + 'px'
        : '';
      stickyEl.style.paddingLeft = applyStyleOverrides ? padding + 'px' : '';
      stickyEl.style.paddingRight = applyStyleOverrides ? padding + 'px' : '';
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll, { passive: true });
      window.addEventListener('resize', handleResize);

      handleScroll();
    }

    return () => {
      window.removeEventListener('scroll', handleScroll, { passive: true });
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <PricingBlockContainerStyles>
      <Container className="block-container">
        {blockSection.map(item => (
          <div
            className={`block-section ${item?.planBlock && 'block-menu'}`}
            key={item.internalName}
          >
            <Row key={item.internalName} className="block-row">
              <Col
                className="description"
                lg={item.sectionTitle === 'Plans' ? 3 : 12}
              >
                {item?.sectionTitle && (
                  <p
                    className={`${item.sectionTitle === 'Plans' &&
                      'heading-4'} ${item.sectionTitle !== 'Plans' &&
                      'section-margin'}`}
                  >
                    {item.sectionTitle}
                  </p>
                )}
                {item?.subhead && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.subhead.replace(
                        'Contact Support',
                        '<a href="https://tinyurl.com/emailshopmonkey">Contact Support</a>'
                      ),
                    }}
                    className="subhead"
                  />
                )}
              </Col>
              {item?.planBlock?.pricingCards.slice(0, 4).map((plan, pindex) => (
                <Col
                  className={`perk-col ${
                    item?.sectionTitle === 'Payments' ? 'perk-col-margin' : ''
                  }`}
                  key={plan.internalName}
                >
                  <div
                    className={`perk-block ${pindex == 2 ? 'preferred' : ''}`}
                  >
                    <div className="perk-block-content">
                      <p className="title">
                        {plan?.annualPrice == 699
                          ? 'Multi-Shop'
                          : plan.cardTitle}
                      </p>

                      {plan?.annualPrice &&
                        (plan?.annualPrice == 699 ? (
                          <div className="price call-price">
                            <p>
                              <small>Custom Pricing</small>
                            </p>
                          </div>
                        ) : (
                          <div className="price">
                            ${' '}
                            <p className="number">
                              {isAnnualPlan
                                ? plan.annualPrice
                                : plan.monthlyPrice}
                            </p>{' '}
                            /mo
                          </div>
                        ))}
                      {plan?.icon?.image && (
                        <figure className="enterprise-icon">
                          {plan?.icon?.image?.gatsbyImageData ? (
                            <Img
                              image={plan.icon.image.gatsbyImageData}
                              alt={plan.icon.internalName}
                              title={plan.icon.image.title}
                            />
                          ) : (
                            <Img
                              src={plan.icon.image.url}
                              alt={plan.icon.internalName}
                              title={plan.icon.image.title}
                            />
                          )}
                        </figure>
                      )}
                      <p className="billing">
                        {plan?.annualPrice
                          ? `Billed ${isAnnualPlan ? 'Annually' : 'Monthly'}`
                          : 'Enterprise / HD'}
                      </p>
                    </div>
                  </div>
                  {/*  */}
                  <CallToAction
                    variant={
                      isScrolled
                        ? 'linkColor'
                        : pindex == 2
                        ? 'primary'
                        : 'tertiary'
                    }
                    size="lg"
                    className="pricing-btn"
                    link={plan.link.url}
                    value={isAnnualPlan ? 'Contact Us' : plan.link.label}
                  />
                  {/*  */}
                </Col>
              ))}
            </Row>
            {item.planPerks.map(perk => (
              <Row key={perk.internalName} className="perk-row">
                <Col lg={3}>
                  <Row
                    style={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div className="perk-title">{perk.perkTitle}</div>
                    {perk.tooltipContent ? (
                      <ToolTip content={perk.tooltipContent} />
                    ) : null}
                  </Row>
                </Col>

                {perk.perkBlocks.slice(0, 4).map((block, index) => (
                  <Col
                    className={`perk-col ${(item.sectionTitle === 'Plans' ||
                      item.sectionTitle === 'Payments') &&
                      'perk-col-margin'}`}
                    key={block.internalName}
                  >
                    <div
                      className={`small-block ${index == 2 ? 'preferred' : ''}`}
                    >
                      {block?.perkIcon?.image && (
                        <figure
                          className={
                            block.perkIcon.internalName
                              .toLowerCase()
                              .includes('minus')
                              ? 'gray-img'
                              : ''
                          }
                        >
                          {block?.perkIcon?.image?.gatsbyImageData ? (
                            <Img
                              image={block.perkIcon.image.url}
                              alt={block.perkIcon.internalName}
                              title={block.perkIcon.image.title}
                            />
                          ) : (
                            <Img
                              src={block.perkIcon.image.url}
                              alt={block.perkIcon.internalName}
                              title={block.perkIcon.image.title}
                            />
                          )}
                        </figure>
                      )}
                      {block?.perkText && (
                        <div
                          dangerouslySetInnerHTML={{ __html: block.perkText }}
                          className="subhead"
                        />
                      )}
                    </div>
                  </Col>
                ))}
              </Row>
            ))}
          </div>
        ))}
      </Container>
    </PricingBlockContainerStyles>
  );
};

export default PricingBlockContainer;
